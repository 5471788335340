/* featured-shops.css Start */

.home {
  .featured-shops__order-0 {
    position: relative;
    padding: 78px 0 10px;
    background: #FFF;

    @media #{$md} {
      padding-top: 38px;
    }

    @media #{$xs} {
      margin-top: 45px;
    }

    h2 {
      margin-top: 0;
      color: #252525;
      font-weight: 900;
      font-size: 37px;
      text-align: center;
    }

    .more-link {
      @media #{$min-sm} {
        top: auto;
        bottom: 0;
      }
    }

    &::before {
      position: absolute;
      top: -57px;
      left: 50%;
      transform: translateX(-50%);
      content: url("https://www.rebatly.com.br/static/assets/images/heart-logo.png");

      @media #{$md} {
        top: -59px;
        transform: translateX(-50%) scale(0.7);
      }
    }
  }
}

.pannacotta {
  .featured-shops {
    padding-top: 24px;

    .flexslider {
      &__item {
        justify-content: space-evenly;

        @media #{$min-xs} and #{$sm} {
          column-gap: 15%;
        }

        @media (min-width: 470px) and (max-width: 516px) {
          column-gap: 4%;
        }

        a {
          max-width: 145px;
          height: auto;
          padding: 10px;
          overflow: hidden;
          border-radius: 50%;

          @media #{$md} {
            max-width: 110px;
          }
        }
      }
    }
  }
}

/* featured-shops.css End */

/* coupons.css Start */

$rebatly-green: #078366;
$rebatly-darkgreen: #075F4A;

.pannacotta {
  .card-coupon-filter {
    ul {
      li {
        background-color: $rebatly-green;
        border: 1px solid $rebatly-green;

        &.active {
          background-color: $rebatly-darkgreen;
        }
      }
    }
  }

  .card-coupon-filter {
    .category-filter .dropdown-toggle,
    .shop-filter .dropdown-toggle {
      font-size: 12px;
      border: 1px solid $rebatly-green;
    }
  }
}

/* coupons.css End */
