$background-attachment: scroll;
$background-position: 0 0;
$background-repeat: repeat;
$background-size: auto;
$color-dark-raspberry: #B30035;
$color-ashes: #4F5764;
$color-berry: #8801FF;
$color-cloud: #ECF0F1;
$color-cherry: #E6314C;
$color-freedom: #2ECC71;
$color-juicy: #F05632;
$color-ready: #B30035;
$color-royal: #7200FF;
$color-sky: #004CCC;
$color-border: #D8D8D8;
$color-card-bg: #F9F9F9;
$color-title: #262626;
$color-headline: #404040;
$color-subheadline: #737373;
$color-text: #404040;
$color-bg-hero: #BBB;
$color-inactive: #C6CFD1;
$color-body-bg: #F6F6F6;
$color-header-bg: #F6F6F6;
$color-white: #FFF;
$color-cta: $color-dark-raspberry;
$color-sidebar-font: #333;

// Offer Carousel
$offer-carousel-main-color: $color-dark-raspberry;

// Flyout
$flyout__background: $color-body-bg;
$flyout-before__color: $color-dark-raspberry;
$flyout__clickout: $color-dark-raspberry;
$flyout__button-color: $color-white;

// Coupons verified label
$coupon-verified__bg-color: transparent;

// Author widget
$colors: (
  "color-border": $color-border
);
$author-widget-name__color: $color-ready;
$author-widget-profession__color: $color-sidebar-font;
$author-widget-profession__font-size: 14px;
$author-widget-bio__color: $color-sidebar-font;
$author-widget-bio__font-size: 14px;
$author-widget-bio__line-height--mobile: 30px;
$author-widget-additional-info__color: $author-widget-bio__color;
$author-widget-additional-info__font-size: $author-widget-bio__font-size;
$author-widget-additional-info__line-height--mobile: $author-widget-bio__line-height--mobile;
$author-widget-reviewed__color: $author-widget-bio__color;
$author-widget-reviewed__font-size: $author-widget-bio__font-size;
$author-widget-reviewed__line-height--mobile: $author-widget-bio__line-height--mobile;
$author-widget-page-link__color: $color-sky;
$author-widget-page-link-before__width: 10px;
$author-widget-page-link-before__height: 10px;
$author-widget-page-link-before__bg-color: $color-sky;

// Responsive breakpoints
$xs: "(max-width: 480px)";
$sm: "(max-width: 767px)";
$md: "(max-width: 991px)";
$lg: "(max-width: 1199px)";
$min-xs: "(min-width: 480px)";
$min-sm: "(min-width: 768px)";
$min-md: "(min-width: 992px)";
$min-lg: "(min-width: 1200px)";
