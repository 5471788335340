@font-face {
  font-weight: 300;
  font-family: Museo;
  font-style: normal;
  src: url("https://www.rebatly.com.br/static/pc/assets/rebatly_flat/MuseoCyrl-300.otf");
}

@font-face {
  font-weight: 500;
  font-family: Museo;
  font-style: normal;
  src: url("https://www.rebatly.com.br/static/pc/assets/rebatly_flat/MuseoCyrl-500.otf");
}

@font-face {
  font-weight: 700;
  font-family: Museo;
  font-style: normal;
  src: url("https://www.rebatly.com.br/static/pc/assets/rebatly_flat/MuseoCyrl-700.otf");
}

@font-face {
  font-weight: 900;
  font-family: Museo;
  font-style: normal;
  src: url("https://www.rebatly.com.br/static/pc/assets/rebatly_flat/MuseoCyrl-900.otf");
}

@font-face {
  font-weight: 100;
  font-family: "Museo Sans";
  src: url("https://www.rebatly.com.br/static/pc/assets/rebatly_flat/MuseoSansCyrl-100.otf");
}

@font-face {
  font-weight: 300;
  font-family: "Museo Sans";
  font-style: normal;
  src: url("https://www.rebatly.com.br/static/pc/assets/rebatly_flat/MuseoSansCyrl-300.otf");
}

@font-face {
  font-weight: 500;
  font-family: "Museo Sans";
  font-style: normal;
  src: url("https://www.rebatly.com.br/static/pc/assets/rebatly_flat/MuseoSansCyrl-500.otf");
}

@font-face {
  font-weight: 500;
  font-family: "Museo Sans";
  font-style: italic;
  src: url("https://www.rebatly.com.br/static/pc/assets/rebatly_flat/MuseoSansCyrl-500Italic.otf");
}

@font-face {
  font-weight: 700;
  font-family: "Museo Sans";
  font-style: normal;
  src: url("https://www.rebatly.com.br/static/pc/assets/rebatly_flat/MuseoSansCyrl-700.otf");
}

@font-face {
  font-weight: 900;
  font-family: "Museo Sans";
  font-style: normal;
  src: url("https://www.rebatly.com.br/static/pc/assets/rebatly_flat/MuseoSansCyrl-900.otf");
}

@font-face {
  font-weight: 900;
  font-family: "Museo Sans";
  font-style: italic;
  src: url("https://www.rebatly.com.br/static/pc/assets/rebatly_flat/MuseoSansCyrl-900Italic.otf");
}

body.pannacotta {
  font-family: "Museo Sans", sans-serif;
}

.pannacotta {
  #header {
    background-color: #3F51B5;

    .site-logo {
      @media #{$min-md} {
        margin: 27px 0 19px;
      }

      img {
        @media #{$min-sm} {
          max-height: 62px;
        }
      }
    }

    #main-menu > ul > li {
      @media #{$min-sm} {
        height: 100px;

        &.open > a,
        &.active > a {
          color: #FFCDDB;
        }
      }

      > a {
        @media #{$min-sm} {
          color: #FFF;

          &:focus,
          &:hover {
            color: #FFCDDB;
          }
        }
      }
    }

    .search-header {
      @media #{$min-sm} {
        background: #FFF;
        border: none;
      }
    }
  }

  .sub-menu {
    @media #{$min-sm} {
      top: 100px;
    }
  }

  .no-topbar .sub-menu,
  .sub-menu__categories,
  .sub-menu__specials {
    @media #{$min-md} {
      top: 99px;
    }

    a {
      @media #{$min-sm} {
        font-weight: 400;
      }
    }
  }

  .card-breadcrumbs .card-content .breadcrumb {
    font-size: 14px;
  }

  .btn-ready {
    padding: 13px 17px;
  }
}

.no-topbar {
  .pannacotta.sub-menu {
    @media #{$min-sm} {
      top: 100px;
    }
  }
}

.featured-shops__order-0 .flex-default-style .flexslider--home {
  min-height: 180px;
}

.premium-campaign-slider {
  &__button {
    background-color: $color-ready;

    &:hover {
      background-color: darken($color-ready, 10%);
    }
  }
}

.pannacotta.card-shops-index .card-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }
}

.card-static-header {
  text-align: left;
}

.pannacotta.card-coupons-list .card-content {
  .coupon-description {
    font-weight: 400;
  }

  & > ul li.item .show-coupon-details {
    color: $color-sky;
  }
}

@media (min-width: 768px) {
  .pannacotta.card-coupons-list .card-content > ul li.item {
    .coupon-footer {
      width: 35%;
    }

    .coupon-body {
      width: 45%;
    }
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .pannacotta.card-coupons-list .card-content > ul li.item .coupon-footer {
    padding: 0;
  }

  .pannacotta .btn-ready {
    padding: 13px 10px !important;
  }
}

@media (max-width: 1199px) and (min-width: 768px) {
  .coupon-cta .btn-group button {
    padding: 13px 11px;
  }
}

.coupons__item {
  @media #{$min-sm} {
    grid-template-columns: 136px auto 1fr 1fr 1fr 1fr;
  }
}

// The rest CSS

#my-modal {
  .modal {
    &__content {
      .coupon {
        &__title {
          margin-bottom: 8px;

          @media #{$sm} {
            width: 90%;
            max-height: unset;
          }
        }
      }
    }

    &__logo {
      margin-bottom: 10px;

      @media #{$sm} {
        width: 100%;
        margin-bottom: 7px;
      }
    }

    &__expired {
      width: unset;
      margin: 0 0 5px;
      white-space: nowrap;

      @media #{$sm} {
        padding: 0;
      }

      &::before {
        margin-right: 5px;
        content: url("data:image/svg+xml;charset=utf-8,%3Csvg width='12' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 6A6 6 0 110 6a6 6 0 0112 0zM6 2.625a.375.375 0 10-.75 0V6.75a.375.375 0 00.189.325l2.625 1.5a.375.375 0 00.372-.65L6 6.532V2.625z' fill='%23666'/%3E%3C/svg%3E");
      }
    }
  }
}

.pannacotta#footer {
  font-weight: 400;
  background-color: #252525;
}

.pannacotta.card-text {
  .author-widget {
    &__profession,
    &__reviewed,
    &__bio {
      color: $color-text;
      font-weight: 100;
    }
  }
}

#sidebar {
  .author-widget {
    &__profession,
    &__reviewed,
    &__bio {
      @media #{$sm} {
        font-weight: 100;
        line-height: 30px;
      }
    }
  }
}
